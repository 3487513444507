import Translate from "@identitybuilding/idb-react-translations";
let lang = window.location.href.includes("lang=fr") ? "fr" : "nl";

const initialState = {
  translate: (x) => Translate(x, lang),
  data: {
    colors: {
      main: "#EE2E24",
      sub: "#FD8628",
    },
    data: {
      about: {
        description: "",
        img: "",
      },
      activitiesServices: "",
      address: {
        cellphone: "",
        city: "",
        number: "",
        phone: "",
        postalcode: "",
        qr: "",
        qr_invert: "",
        street: "",
        vat: "",
      },
      currentOffers: "",
      enterprisename: "",
      featuredimg: "",
      legalform: "",
      logo: "",
      logos: "",
      magazines: "",
      menu: {
        openinghours: "",
      },
      name: "",
      newsArticles: "",
      options: {
        about_us: {
          template: 4,
          text: "",
          theme: "sub",
          title: "delhaize proxy muizen",
        },
        contact: {
          deleteTitle: "",
          title: "",
        },
        current_offers: {
          display: false,
          theme: "light",
          title: "",
        },
        e_shop: {
          // image: "https://delhaizeproxymuizen.be/images/eshop.jpg",
          image: require("../library/images/eshop.jpg"),
          template: 1,
          theme: "dark",
          title: "Bezoek onze e-shop",
        },
        floorplan: {
          image: require(`../library/images/grondplan_${lang}.svg`).default,
          template: 1,
          theme: "light",
          title: "Ons grondplan",
        },
        footer: {
          contact: true,
          sitemap: true,
          socialMedia: {
            icon: "Facebook",
            value: "https://www.facebook.com/identityBuilding",
          },
          theme: "dark",
        },
        home: {
          background: "image",
          fullscreen: true,
          height: "half",
          image: require("../library/images/background.jpg").default,
          logoSlogoµvisibility: "visable",
          logoVisibility: true,
          logo_and_slogan_visibility: true,
          logo_visibility: false,
          opacity: "0.2",
          overlay_opacity: "0.2",
          slogan: "",
          sloganTemp: 2,
          slogan_template: 1,
          theme: "gradient",
          type: "image",
          video:
            "https://o-sn.be/seg/www.100procentlokaal.be%20-%20voor-steden-en-gemeenten.mp4",
        },
        job_application: {
          template: 3,
          theme: "light",
          title: "",
        },

        // zorgen voor FR in url en title
        recepten: {
          aperitif: [
            {
              title_nl: "Noorse zalmballetjes met tuinkruiden en yoghurtdip",
              url_nl: "https://youtube.be/embed/VuhUxP-Mbus",
              title_fr:
                "Boulettes nordiques de saumon aux herbes fraîches et dip au yaourt",
              url_fr: "https://youtu.be/OB_r8nogCB8",
            },
            {
              title_nl: "Crostinis met geitenkaas en peperconfiture",
              url_nl: "https://youtube.be/embed/vIoUzo3ZRao",
              title_fr: "Crostinis au chèvre et la confiture de poivrons",
              url_fr: "https://youtube.be/embed/6y_RgspStyA",
            },
            {
              title_nl: "Blini's met zalm, groene appel en guacamole",
              url_nl: "https://youtube.be/embed/c22pU_IkNgc",
              title_fr: "Blinis au saumon, pomme verte et guacamole",
              url_fr: "https://youtube.be/embed/RA4bpkASp4Q",
            },
            {
              title_nl: "Nems met kip",
              url_nl: "https://youtube.be/embed/u-3qWccRkf0",
              title_fr: "Nems au poulet",
              url_fr: "https://youtube.be/embed/dkMYDyRcMgU",
            },
            {
              title_nl: "Bloemkoolschaapjes in de wei",
              url_nl: "https://youtube.be/embed/rKzknmzxncw",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Pikante tomatensalsa en zelfgemaakte taco's",
              url_nl: "https://youtube.be/embed/IzfjoOEaY4g",
              title_fr: "Salsa épicée aux tomates et tacos maison",
              url_fr: "https://www.youtube.com/embed/029m8vnwp9M",
            },
            {
              title_nl:
                'Kabeljauwbeignets in een korst van "Oud Beersel" met zure room en bieslook',
              url_nl: "https://youtube.be/embed/ORYk-GUxTwA",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Guaca-watte",
              url_nl: "https://youtube.be/embed/LsNykjHzUtM",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Kaaskroketten met truffel",
              url_nl: "https://youtube.be/embed/gF406RkMYGI",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Gefrituurde mozzarellaballetjes",
              url_nl: "https://youtube.be/embed/Qfem8F_dcjg",
              title_fr: "",
              url_fr: "",
            },
          ],
          side_dish: [
            {
              title_nl: "Zoete aardappelsoufflés",
              url_nl: "https://youtube.be/embed/21qVeOTV7wg",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Zwemmende patatjes",
              url_nl: "https://youtube.be/embed/85VFVo4wGNc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Duizendpotige pastinaak",
              url_nl: "https://youtube.be/embed/f6CvaVH1uqw",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Barbecuesaus met 'Jack Daniels' en ribbetjes",
              url_nl: "https://youtube.be/embed/CzTFHA8k9X8",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Witloof met basilicum, bruine suiker en sinaasappelsap",
              url_nl: "https://youtube.be/embed/7xCVobwCTN0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Gratin dauphinois",
              url_nl: "https://youtube.be/embed/9khf_xZrGdA",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Meloenconfituur met groene thee",
              url_nl: "https://youtube.be/embed/eiXRjO2TXII",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Aardappelen in papillot met kaffirblad",
              url_nl: "https://youtube.be/embed/ZoBnYId5AVM",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Indische rijstsalade",
              url_nl: "https://youtube.be/embed/x-Pz7vMxd1Y",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Tian van tomaat en courgette",
              url_nl: "https://youtube.be/embed/5JXbctjO_xk",
              title_fr: "",
              url_fr: "",
            },
          ],
          drink: [
            {
              title_nl: "Icetea met limoen, citroen en blauwe bessen",
              url_nl: "https://youtube.be/embed/AZ9x3k3C26Y",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Passionata",
              url_nl: "https://youtube.be/embed/HmRpmzzmpZg",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Tropische Smoothie",
              url_nl: "https://youtube.be/embed/JbAoV8di90E",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Breakfast shake: Smoothie met komkommer, citroen en munt",
              url_nl: "https://youtube.be/embed/fUI-FzR0kuQ",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Abricodabra",
              url_nl: "https://youtube.be/embed/lrzwDmY5DTc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Toverdrankje",
              url_nl: "https://youtube.be/embed/jlauVpBd6L4",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Roodkapje",
              url_nl: "https://youtube.be/embed/27eYvBahuY4",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Delhaize 150 year champagne cocktail",
              url_nl: "https://youtube.be/embed/Ct7LSBvPfgA",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "The Anejo & Ginger cocktail",
              url_nl: "https://youtube.be/embed/IxDk43Peqjo",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "French Connection",
              url_nl: "https://youtube.be/embed/giUGrWq8d2o",
              title_fr: "",
              url_fr: "",
            },
          ],
          main_dish: [
            {
              title_nl: "Lenterolletjes met maatjes",
              url_nl: "https://youtube.be/embed/_XUAP1fYyq0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Sardines gevuld met verse kruiden",
              url_nl: "https://youtube.be/embed/QyL5am5bbaI",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Tongscharfilets met tapenade en pastasalade alla Caprese",
              url_nl: "https://youtube.be/embed/CiCK_WHEqhA",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Taco's van koolvis met zeebanaantjes",
              url_nl: "https://youtube.be/embed/WUz8-2mQNNI",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Preirisotto met spek en een eitje",
              url_nl: "https://youtube.be/embed/CfgV9jEnFLo",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Tagliata van presa ibérico met girasoli, groentjes en gekonfijte tomaat",
              url_nl: "https://youtube.be/embed/rX1xePv5xsg",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Groentestoofpotje met chipolata's",
              url_nl: "https://youtube.be/embed/j785GanbWsY",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Zeewolf in papillot met grijze garnalen en kokkels",
              url_nl: "https://youtube.be/embed/Yq1jhUUTDr0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Kipfilets gebraden op lage temperatuur",
              url_nl: "https://youtube.be/embed/Aw4S4-TtBpM",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Groene lamscurry met groenten",
              url_nl: "https://youtube.be/embed/vvl6WtWba5o",
              title_fr: "",
              url_fr: "",
            },
          ],
          dessert: [
            {
              title_nl: "Chocoladebombe op z’n Italiaans",
              url_nl: "https://youtube.be/embed/Bx8tCcjKyp0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Sterrenappeltaart",
              url_nl: "https://youtube.be/embed/rY-b2YtMDps",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Exotische lollies",
              url_nl: "https://youtube.be/embed/6PeCXvVCMS0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Vederlichte donuts",
              url_nl: "https://youtube.be/embed/kgdNn6WtM7Q",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Ice cream sandwiches met pistache en veenbessen",
              url_nl: "https://youtube.be/embed/Zxcn8LnZW90",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Aardbeientaart",
              url_nl: "https://youtube.be/embed/8nHmFBFxp4c",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Dadelwafeltjes",
              url_nl: "https://youtube.be/embed/lvCvYtVwC-0",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Verjaardagstaart met blauwe bessen",
              url_nl: "https://youtube.be/embed/GfG2szRYeR8",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Chocolade-eclairs met grand cru pure chocolade",
              url_nl: "https://youtube.be/embed/SNn3MUDXW9k",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Marsepeinkusjes",
              url_nl: "https://youtube.be/embed/oRTLYCpAEhw",
              title_fr: "",
              url_fr: "",
            },
          ],
          snacks: [
            {
              title_nl: "Power balls",
              url_nl: "https://youtube.be/embed/3tQvK1ICy3M",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Bere-lekkere preigratin",
              url_nl: "https://youtube.be/embed/blObgzMCji4",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Appelmonsters met druiven",
              url_nl: "https://youtube.be/embed/qdpmwkqHp-4",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Yoghurt met rode biet en sinaasappel",
              url_nl: "https://youtube.be/embed/hStLBS27HVo",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Mini sushi burgers",
              url_nl: "https://youtube.be/embed/MP6H3LzwcFs",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Banana bread",
              url_nl: "https://youtube.be/embed/-nTY2s52_XY",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Macaronikroketjes met abrikozencompote",
              url_nl: "https://youtube.be/embed/DXmGIOY1CKE",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Sesamrepen met rozijnen en cranberries",
              url_nl: "https://youtube.be/embed/4J9vS1AtLtg",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Mueslireep met fruit en noten, geglaceerd met yoghurt",
              url_nl: "https://youtube.be/embed/KEd9_cYbZxY",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Banaan-kokos-chocoladehapjes",
              url_nl: "https://youtube.be/embed/PV0NwwM3N6M",
              title_fr: "",
              url_fr: "",
            },
          ],
          soup: [
            {
              title_nl: "Miso ramen",
              url_nl: "https://youtube.be/embed/klnvBUHzNrM",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Crème van linzen",
              url_nl: "https://youtube.be/embed/pcZfDK9LGpc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Gambasoep met curry en paksoi",
              url_nl: "https://youtube.be/embed/SAThQy8Avis",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Knolselder-pastinaaksoep met citroenroom en zalmeitjes",
              url_nl: "https://youtube.be/embed/69BsWMUVVq8",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Komkommergazpacho met waterkers",
              url_nl: "https://youtube.be/embed/jSa0SYrfE5o",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Maaltijdbouillon met balletjes en spelt",
              url_nl: "https://youtube.be/embed/QJcQ_EmBVOY",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Witloofsoep met grijze garnalen",
              url_nl: "https://youtube.be/embed/JswnOuI5fnc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Pittige pompoensoep",
              url_nl: "https://youtube.be/embed/koiQXXQA54g",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Gazpacho van meloen met geitenkaas",
              url_nl: "https://youtube.be/embed/x9tCn6ctOCk",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Travel soup",
              url_nl: "https://youtube.be/embed/SH3cQAlLLdg",
              title_fr: "",
              url_fr: "",
            },
          ],
          appetizer: [
            {
              title_nl: "Groenekoolmousse met ham",
              url_nl: "https://youtube.be/embed/L6RHSjLtrbA",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Tonijntartaar met asperges",
              url_nl: "https://youtube.be/embed/1UbOLKeAlyQ",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Rolletjes van Schotse gerookte zalm in norivellen",
              url_nl: "https://youtube.be/embed/D_WwycrDVgc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Kort gebakken sint-jakobsnoten met witloof en botersaus met citrusvruchten",
              url_nl: "https://youtube.be/embed/krIQaDHJlTM",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Trio van gegrilde oesters",
              url_nl: "https://youtube.be/embed/Lyb2hMTKhsI",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Zalmcarpaccio met ijzerkruid",
              url_nl: "https://youtube.be/embed/4uuEOfAb-9E",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Ceviche van zeebaars",
              url_nl: "https://youtube.be/embed/cLy0qe00Oao",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Sint-jakobsvruchten met exotisch fruit en vanille",
              url_nl: "https://youtube.be/embed/9YtotbaloBw",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl: "Gegratineerde butternut met geitenkaas",
              url_nl: "https://youtube.be/embed/q20neeybAGc",
              title_fr: "",
              url_fr: "",
            },
            {
              title_nl:
                "Carpaccio van sint-jakobsnootjes met tuinkruiden en citroendressing",
              url_nl: "https://youtube.be/embed/M7XAslsoess",
              title_fr: "",
              url_fr: "",
            },
          ],
        },
      },
      promos: "",
      services: {
        associations: "",
        facilities: "",
        payment_methods: "",
      },
      slogan: "",
      socialMedia: {
        facebookId: "154969861293378",
        instaId: "",
        title: "Our social news",
        token:
          "EAAHMdBPBje8BAI5xW4GaugVIZCx0QcJMwDubYcKCFUHqteQ3ZB3lMVV05kJDVOiE8stiJfX0mXxWdksZB5CrQruXDL5Py9i2yaRXgPO25sUFZAoX4E3LINNbRKQ7lEA79kolt1et5Wi5KIY3WDhI2KWKep4qg5ugZBEtwi2i1rEv04mOAc708FA8bwCnsyyMZD",
      },
      team: "",
      teamPicture: "",
      video: "",
      ytvideo: "",
    },
    isloaded: false,
  },
  est: "BE2038929211",
  folder_name: "ADROESELARE",
  vat: "BE0434193675",
  captcha_key: "6Lc9ab8bAAAAAEKPgGMwcq3jwMp5VLUpgZEpzawy",
  instagram_name: "ad.delhaize.roeselare",
  instagram_id: "elfsight-app-a444c16e-2c0a-442b-8b38-def6e657377b",
  lang: lang,
  main_lang: "nl",
  isloaded: false,
  modules: [
    {
      name: "Home",
      alias: "home",
      new_page: false,
      active: true,
      preview: require("../library/images/header.png").default,
      slug: "home",
      title: "home",
      display_oh: true,
      module: "",
    },
    {
      name: "Ons Verhaal",
      alias: "currentOffers",
      new_page: false,
      active: true,
      preview: require("../library/images/current-offers.png").default,
      slug: "ons-verhaal",
      title: "our_story",
      module: "",
    },
    {
      name: "Instagram",
      alias: "instagram",
      new_page: false,
      active: true,
      preview: require("../library/images/contact.png").default,
      slug: "instagram",
      title: "Instagram",
      module: "",
    },
    {
      name: "E-Shop",
      alias: "e-shop",
      new_page: false,
      active: false,
      preview: require("../library/images/current-offers.png").default,
      slug: "e-shop",
      title: "eshop",
      module: "",
    },
    {
      name: "Socials",
      alias: "social",
      preview: require("../library/images/team.png").default,
      new_page: false,
      active: false,
      slug: "social-news",
      title: "social_news",
      module: "",
    },
    {
      name: "Floorplan",
      alias: "floorplan",
      new_page: false,
      active: false,
      preview: require("../library/images/current-offers.png").default,
      slug: "floorplan",
      title: "floorplan",
      module: "",
    },
    {
      name: "Digital Display",
      alias: "digitalDisplay",
      new_page: false,
      active: true,
      slug: "digital-display",
      preview: require("../library/images/digital-display.png").default,
      title: "promos_and_magazines",
      module: "",
    },
    {
      name: "Contact and Openinghours",
      alias: "contact",
      new_page: false,
      active: true,
      preview: require("../library/images/contact.png").default,
      slug: "contact",
      title: "contact_and_opening_hours",
      module: "",
    },
    {
      name: "Vacature",
      alias: "vacature",
      new_page: false,
      active: true,
      preview: require("../library/images/current-offers.png").default,
      slug: "vacature",
      title: "vacancies",
      module: "",
    },
    {
      name: "Recepten",
      alias: "recepten",
      active: true,
      new_page: true,
      preview: require("../library/images/current-offers.png").default,
      slug: "recepten",
      title: "recipes",
      module: "",
    },
    {
      name: "Photo album",
      alias: "photo_album",
      active: false,
      new_page: true,
      preview: require("../library/images/current-offers.png").default,
      slug: "fotoalbum",
      title: "photo_album",
      module: "",
    },
    {
      name: "Team",
      preview: require("../library/images/team.png").default,
      active: false,
      new_page: false,
      slug: "team",
      module: "",
    },
    {
      name: "Job application",
      preview: require("../library/images/team.png").default,
      active: false,
      new_page: false,
      slug: "job-application",
      module: "",
    },
    {
      name: "About us",
      active: false,
      new_page: false,
      preview: require("../library/images/about-us.png").default,
      slug: "about-us",
      module: "",
    },
    // {
    //     name: "News",
    //     preview: require("../library/images/team.png").default,
    //     active: false,
    //     new_page: false,
    //     slug: "news",
    //     module: "",
    // },
    // {
    //     name: "Our work",
    //     preview: require("../library/images/team.png").default,
    //     new_page: false,
    //     active: false,
    //     slug: "our-work",
    //     module: "",
    // },
    // {
    //     name: "Reviews",
    //     preview: require("../library/images/team.png").default,
    //     active: false,
    //     new_page: false,
    //     slug: "reviews",
    //     module: "",
    // },
    // {
    //     name: "Quote",
    //     preview: require("../library/images/team.png").default,
    //     active: false,
    //     new_page: false,
    //     slug: "quote",
    //     module: "",
    // },
    {
      name: "Footer",
      active: true,
      new_page: false,
      preview: require("../library/images/footer.png").default,
      slug: "footer",
      title: "footer",
      module: "",
    },
  ],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_MODULES": {
      return {
        ...state,
        modules: action.items,
      };
    }
    case "UPDATE_EST": {
      return {
        ...state,
        est: action.est,
      };
    }
    case "UPDATE_DATA": {
      return {
        ...state,
        data: action.data,
      };
    }
    case "UPDATE_COLORS": {
      return {
        ...state,
        colors: action.colors,
      };
    }
    case "UPDATE_LANG": {
      return {
        ...state,
        lang: action.lang,
      };
    }
    case "UPDATE_ISLOADED": {
      return {
        ...state,
        isloaded: action.isloaded,
      };
    }
    default:
      return state;
  }
};

export default AdminReducer;
